<template>
    <div class="order">
        <el-table ref="mainTable" :data="dataList" style="width: 100%" stripe border>
            <el-table-column prop="username" label="用户名" width="150" align="center"></el-table-column>
            <el-table-column prop="fullName" label="姓名" width="150" align="center"></el-table-column>
            <el-table-column prop="mobile" label="手机号码" width="200" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleClick('password', scope.row)" type="text" size="small">更新密码</el-button>
                    <el-button @click="handleClick('edit', scope.row)" type="text" size="small">编辑</el-button>
                    <el-button @click="handleClick('delete', scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="totalCount > pageSize" class="pt" background layout="prev, pager, next" @current-change="changePage" :page-size="pageSize" :total="totalCount"> </el-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            dataList: [],
        };
    },
    filters: {
        getImageUrl: function(val, cdn) {
            return cdn + val;
        },
    },
    mounted() {
        this.loadDataList();
    },
    methods: {
        formatter(row, column, value) {
            return value;
        },
        loadDataList: function() {
            this.$http({
                url: "/sys/admin/list",
                data: {
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                },
            }).then((res) => {
                this.totalCount = parseInt(res.data.total);
                this.dataList = res.data.records;
            });
        },
        handleClick: function(action, row) {
            // 删除
            if ("delete" == action) {
                this.$confirm("确定要删除该账号吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$http({
                            url: "/sys/admin/delete",
                            data: {
                                id: row.id,
                            },
                        }).then((res) => {
                            this.loadDataList();
                        });
                    })
                    .catch(() => {});
            }
            // 编辑
            if ("edit" == action) {
                this.$router.push("/admin/create?userId=" + row.id);
            }
            // 修改密码
            if ("password" == action) {
                this.$prompt("请输入新密码", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    inputErrorMessage: "内容不能为空",
                    inputValidator: (value) => {
                        if (!value) {
                            return "内容不能为空";
                        }
                    },
                })
                    .then(({ value }) => {
                        this.$http({
                            url: "/sys/admin/changePassword",
                            data: {
                                id: row.id,
                                password: value,
                            },
                        }).then((res) => {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                            });
                            this.loadDataList();
                        });
                    })
                    .catch(() => {});
            }
        },
        // 翻页
        changePage: function(value) {
            this.pageNo = value;
            this.loadDataList();
        },
    },
};
</script>
<style></style>
